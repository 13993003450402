


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import QuestionnaireMixin from "@/mixins/questionnaire.mixin";
import store, { posSessionConfig } from "@/store/store";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import { DEMOGRAPHIC_EHINICITY,DEMOGRAPHIC_GENDER,DEMOGRAPHIC_RACE } from "@/constants";
import _ from "lodash";
import { EthnicityData, RaceData, SubType } from "@/models/pos/demographic-info.model";

@Component
export default class DemographicInformationComponent extends mixins(
  QuestionnaireMixin
) {
  @Prop()
  public history;
  public demographicDetail = null;
  public dotLoader = false;
  public ethnicity = JSON.parse(JSON.stringify(DEMOGRAPHIC_EHINICITY));
  public race = JSON.parse(JSON.stringify(DEMOGRAPHIC_RACE));
  public gender = DEMOGRAPHIC_GENDER;
  public asianSubTypes = ['asianIndian','chinese','filipino','japanese','korean','vietnamese','otherAsian'];
  public pacificIslanderSubTypes = ['nativeHawaiian','guamanianOrChamorro','samoan','otherPacificIslander'];
  public hispanicOrLatinoSubTypes = ['mexican','puertoRican','cuban','other'];

  checkValue(checked, key) {
    if (!checked) {
      switch (key) {
        case "Ethnicity":
          this.demographicDetail.borrowerInfo[
            this.questionHistory.index
          ].ethnicity.description = null;
          break;
        case "OtherAsian":
          this.demographicDetail.borrowerInfo[
            this.questionHistory.index
          ].race.description = null;
          break;
        case "OtherPacificIslander":
          this.demographicDetail.borrowerInfo[
            this.questionHistory.index
          ].race.description = null;
          break;
        case "AmericanIndianOrAlaskaNative":
          this.demographicDetail.borrowerInfo[
            this.questionHistory.index
          ].race.description = null;
          break;
      }
    }
  }

  public async handleLastQuestion(skip) {
    const noOfBorrowers =
      this.questionHistory.indexingFlow.length > 1
        ? this.questionHistory.indexingFlow.length
        : 1;
    let nextIndex = this.getNextIndex(
      this.questionHistory.indexingFlow,
      this.questionHistory.index
    );
    /** handle last question */

    if (!skip) {
      this.lastQuestionConfig.scope = "DI3";
      this.lastQuestionConfig.currentSection = "DI";
      this.lastQuestionConfig.currentQuestion = 3;
      this.lastQuestionConfig.userType = this.questionHistory.userType;
      this.lastQuestionConfig.index = this.questionHistory.index;
      this.lastQuestionConfig.indexingFlow = this.questionHistory.indexingFlow;
    }
    if (noOfBorrowers > 1 && nextIndex) {
      if(skip){
        let updatedHistory = {
            currentQuestion: 1, 
            previousQuestion: 1, 
            currentSection: 'PI',
            userType: 'coBorrower',
            index: nextIndex,
        }
        let data = {
            history: this.questionHistory,
            updatedHistory: updatedHistory,
            posCompleted:false
        }
        this.$emit("callMountFunction",data);
      }else{
        await this.onNext(1,3,'DI3','PI','coBorrower',nextIndex);
      }
    } else {
      if(skip){
        let updatedHistory = {
            currentQuestion: this.$userType == 'Borrower' ? 1 : 2, 
            previousQuestion: 1,
            currentSection: 'AF',
        }
        let data = {
            history: this.questionHistory,
            updatedHistory: updatedHistory,
            posCompleted:false
        }
        this.$emit("callMountFunction",data);
      }else{
        await this.onNext(this.$userType == 'Borrower' ? 1 : 2,3,'DI3','AF');
      }
    }
  }

  public getNextIndex(indexingFlow, index) {
    let currentIndex = indexingFlow.indexOf(index);
    if (currentIndex + 1 < indexingFlow.length) {
      let nextIndex = indexingFlow[currentIndex + 1];
      return nextIndex;
    } else {
      return null;
    }
  }

  public async saveDemographicDetail(nextQuestion?: number, currentQuestion?: number, scope?: string, nextSection?: string, userType?:string, index?:number) {
    try {
      let valid = await this.$validator.validateAll(scope);
      if (!valid) 
        return;
      await this.demographicDetail.borrowerInfo.map(demographicData => this.createBackendObject(demographicData));
      this.$store.state.wemloLoader = true;
      let res = await Axios.post(
        BASE_API_URL + "pos/saveDemographicDetail",
        this.demographicDetail);
      this.$store.state.wemloLoader = false;
      this.$snotify.clear();
      this.$snotify.success("Information saved successfully", {
        timeout: 1000
      });
      if(nextQuestion)
        this.onNext(nextQuestion, currentQuestion, scope, nextSection, userType, index);
      else 
        this.handleLastQuestion(true);
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
      this.$snotify.error(
        "We are unable to save your details. Please try contacting the administrator."
      );
    }
  }
  public async getDemographicDetail() {
    try {
      this.dotLoader = false;
      let res = await Axios.get(BASE_API_URL + "pos/getDemographicDetail", {
        params: {
          loanTxnId: this.$route.query.loanTxnId
        }
      });
      this.dotLoader = false;
      await res.data.borrowerInfo.map(demographicData => this.createFrontObject(demographicData));
      this.demographicDetail = res.data;
    } catch (error) {
      this.dotLoader = false;
    }
  }

  public resetObject(type, data) {
    if(type == 'race.informationNotProvidedByApplicantMailInternetOrTelephoneApplication') {
      data.americanIndianOrAlaskaNative = false;
      data.isAsian = false;
      data.blackOrAfrican = false;
      data.isNativeHawaiianOrOtherPacific = false;
      data.white = false;
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = true;
      data.notApplicable = false;
      data.americanIndianOrAlaskaNativeDescription = null;
      data.asianIndian = false;
      data.chinese = false;
      data.filipino = false;
      data.japanese = false;
      data.korean = false;
      data.vietnamese = false;
      data.otherAsian = false;
      data.asianDescription = null;
      data.nativeHawaiian = false;
      data.guamanianOrChamorro = false;
      data.samoan = false;
      data.otherPacificIslander = false;
      data.otherPacificIslanderDescription = null;
    } else if (type == 'race.notApplicable') {
      data.americanIndianOrAlaskaNative = false;
      data.isAsian = false;
      data.blackOrAfrican = false;
      data.isNativeHawaiianOrOtherPacific = false;
      data.white = false;
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = false;
      data.notApplicable = true;
      data.americanIndianOrAlaskaNativeDescription = null;
      data.asianIndian = false;
      data.chinese = false;
      data.filipino = false;
      data.japanese = false;
      data.korean = false;
      data.vietnamese = false;
      data.otherAsian = false;
      data.asianDescription = null;
      data.nativeHawaiian = false;
      data.guamanianOrChamorro = false;
      data.samoan = false;
      data.otherPacificIslander = false;
      data.otherPacificIslanderDescription = null;
    } else if(type == 'ethnicity.informationNotProvidedByApplicantMailInternetOrTelephoneApplication') {
      data.isHispanicOrLatino = false;
      data.notHispanicOrLatino = false;
      data.notApplicable = false;
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = true;
      data.mexican = false;
      data.puertoRican = false;
      data.cuban = false;
      data.other = false;
      data.description = null;
    } else if(type == 'ethnicity.notApplicable') {
      data.isHispanicOrLatino = false;
      data.notHispanicOrLatino = false;
      data.notApplicable = true;
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = false;
      data.mexican = false;
      data.puertoRican = false;
      data.cuban = false;
      data.other = false;
      data.description = null;
    } else {
      data.informationNotProvidedByApplicantMailInternetOrTelephoneApplication = false;
      data.notApplicable = false;
    }
  }

  public async createFrontObject(demographicData) {
    demographicData.ethnicity = this.createEthnicityFrontObject(demographicData);
    demographicData.race = this.createRaceFrontObject(demographicData);
    return demographicData;
  }

  private createEthnicityFrontObject(demographicData) {
    let ethnicity = {
      isHispanicOrLatino: !!_.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'HispanicOrLatino'),
      notHispanicOrLatino: !!_.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'NotHispanicOrLatino'),
      notApplicable: !!_.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'NotApplicable'),
      informationNotProvidedByApplicantMailInternetOrTelephoneApplication: !!_.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication'),
      mexican: false,
      puertoRican: false,
      cuban: false,
      other: false,
      description: null
    };
    if(ethnicity.isHispanicOrLatino) {
      const hispanicOrLatino = _.get(demographicData, 'ethnicities', []).find(demographic => _.get(demographic, 'ethnicity.mismoValue') == 'HispanicOrLatino');
      this.hispanicOrLatinoSubTypes.forEach(subType => {
        ethnicity[subType] = !!_.get(hispanicOrLatino, 'subtypes', []).find(subtype => subtype.name == subType);
        if(ethnicity.other) ethnicity.description = _.get(_.get(hispanicOrLatino, 'subtypes', []).find(subtype => subtype.name == 'other'), 'description', null);
      });
    }
    return ethnicity;
  }

  private createRaceFrontObject(demographicData) {
    let race = {
      americanIndianOrAlaskaNative: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'AmericanIndianOrAlaskaNative'),
      isAsian: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'Asian'),
      blackOrAfrican: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'BlackOrAfricanAmerican'),
      isNativeHawaiianOrOtherPacific: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'NativeHawaiianOrOtherPacificIslander'),
      white: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'White'),
      informationNotProvidedByApplicantMailInternetOrTelephoneApplication: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication'),
      notApplicable: !!_.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'NotApplicable'),
      americanIndianOrAlaskaNativeDescription: null,
      asianIndian: false,
      chinese: false,
      filipino: false,
      japanese: false,
      korean: false,
      vietnamese: false,
      otherAsian: false,
      asianDescription: null,
      nativeHawaiian: false,
      guamanianOrChamorro: false,
      samoan: false,
      otherPacificIslander: false,
      otherPacificIslanderDescription: null
    };
    if(race.americanIndianOrAlaskaNative) {
      const americanIndianOrAlaskaNative = _.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'AmericanIndianOrAlaskaNative');
      race.americanIndianOrAlaskaNativeDescription = _.get(_.get(americanIndianOrAlaskaNative, 'subtypes', []).find(subtype => subtype.description != null), 'description', null);
    }
    if(race.isAsian) {
      const isAsian = _.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'Asian');
      this.asianSubTypes.forEach(subType => {
        race[subType] = subType == 'otherAsian' ? !!_.get(isAsian, 'subtypes', []).find(subtype => subtype.name == 'other') : !!_.get(isAsian, 'subtypes', []).find(subtype => subtype.name == subType);
        if(race.otherAsian) race.asianDescription = _.get(_.get(isAsian, 'subtypes', []).find(subtype => subtype.name == 'other'), 'description', null);
      });
    }
    if(race.isNativeHawaiianOrOtherPacific) {
      const isNativeHawaiianOrOtherPacific = _.get(demographicData, 'races', []).find(demographic => _.get(demographic, 'race.mismoValue') == 'NativeHawaiianOrOtherPacificIslander');
      this.pacificIslanderSubTypes.forEach(subType => {
        race[subType] = subType == 'otherPacificIslander' ? !!_.get(isNativeHawaiianOrOtherPacific, 'subtypes', []).find(subtype => subtype.name == 'other') : !!_.get(isNativeHawaiianOrOtherPacific, 'subtypes', []).find(subtype => subtype.name == subType);
        if(race.otherPacificIslander) race.otherPacificIslanderDescription = _.get(_.get(isNativeHawaiianOrOtherPacific, 'subtypes', []).find(subtype => subtype.name == 'other'), 'description', null);
      });
    }
    return race;
  }

  private async createBackendObject(demographicData) {
    demographicData.ethnicities = this.createEthnicityBackendObject(demographicData);
    demographicData.races = this.createRaceBackendObject(demographicData);
    return demographicData;
  }

  private createEthnicityBackendObject(demographicData) {
    const ethnicities = [];
    if(demographicData.ethnicity.isHispanicOrLatino) {
      let ethnicity = new EthnicityData();
      ethnicity.ethnicity = this.ethnicity.hispanicOrLatino;
      this.hispanicOrLatinoSubTypes.forEach(subType => {
        if(demographicData.ethnicity[subType]) {
          let subTypeObj = new SubType();
          subTypeObj.name = subType;
          if(subTypeObj.name == 'other') {
            subTypeObj.description = demographicData.ethnicity.description;
          }
          ethnicity.subtypes.push(subTypeObj);
        }
      });
      ethnicities.push(ethnicity);
    }
    if(demographicData.ethnicity.notHispanicOrLatino) {
      let ethnicity = new EthnicityData();
      ethnicity.ethnicity = this.ethnicity.notHispanicOrLatino;
      ethnicities.push(ethnicity);
    }
    if(demographicData.ethnicity.notApplicable) {
      let ethnicity = new EthnicityData();
      ethnicity.ethnicity = this.ethnicity.notApplicable;
      ethnicities.push(ethnicity);
    }
    if(demographicData.ethnicity.informationNotProvidedByApplicantMailInternetOrTelephoneApplication) {
      let ethnicity = new EthnicityData();
      ethnicity.ethnicity = this.ethnicity.informationNotProvidedByApplicantMailInternetOrTelephoneApplication;
      ethnicities.push(ethnicity);
    }
    return ethnicities;
  }

  private createRaceBackendObjectAsian(demographicData) {
    let race = new RaceData();
    race.race = this.race.asian;
    this.asianSubTypes.forEach(subType => {
      if(demographicData.race[subType]) {
        let subTypeObj = new SubType();
        subTypeObj.name = subType;
        if(subTypeObj.name == 'otherAsian') {
          subTypeObj.name = 'other';
          subTypeObj.description = demographicData.race.asianDescription;
        }
        race.subtypes.push(subTypeObj);
      }
    });
    return race;
  }

  private createRaceBackendObjectNativeHawaiianOrOtherPacific(demographicData) {
    let race = new RaceData();
    race.race = this.race.nativeHawaiianOrOtherPacific;
    this.pacificIslanderSubTypes.forEach(subType => {
      if(demographicData.race[subType]) {
        let subTypeObj = new SubType();
        subTypeObj.name = subType;
        if(subTypeObj.name == 'otherPacificIslander') {
          subTypeObj.name = 'other';
          subTypeObj.description = demographicData.race.otherPacificIslanderDescription;
        }
        race.subtypes.push(subTypeObj);
      }
    });
    return race;
  }

  private createRaceBackendObject(demographicData) {
    const races = [];
    if(demographicData.race.americanIndianOrAlaskaNative) {
      let race = new RaceData();
      race.race = this.race.americanIndian;
      let subType = new SubType();
      subType.description = demographicData.race.americanIndianOrAlaskaNativeDescription;
      race.subtypes.push(subType);
      races.push(race);
    }
    if(demographicData.race.isAsian) {
      races.push(this.createRaceBackendObjectAsian(demographicData));
    }
    if(demographicData.race.blackOrAfrican) {
      let race = new RaceData();
      race.race = this.race.blackOrAfrican;
      races.push(race);
    }
    if(demographicData.race.isNativeHawaiianOrOtherPacific) {
      races.push(this.createRaceBackendObjectNativeHawaiianOrOtherPacific(demographicData));
    }
    if(demographicData.race.white) {
      let race = new RaceData();
      race.race = this.race.white;
      races.push(race);
    }
    if(demographicData.race.informationNotProvidedByApplicantMailInternetOrTelephoneApplication) {
      let race = new RaceData();
      race.race = this.race.informationNotProvidedByApplicantMailInternetOrTelephoneApplication;
      races.push(race);
    }
    if(demographicData.race.notApplicable) {
      let race = new RaceData();
      race.race = this.race.notApplicable;
      races.push(race);
    }
    return races;
  }

  public resetAsianSubType(type, data) {
    this.asianSubTypes.forEach(subType => {
      if(type !== 'otherAsian') data.asianDescription = null;
      if(subType === type) data[subType] = true;
      data[subType] = false;
    });
  }

  public resetPacificIslanderSubType(type, data) {
    this.pacificIslanderSubTypes.forEach(subType => {
      if(type !== 'otherPacificIslander') data.otherPacificIslanderDescription = null;
      if(subType === type) data[subType] = true;
      data[subType] = false;
    });
  }

  async mounted() {
    this.questionHistory = this.history;
    await this.getDemographicDetail();
  }
}
